<template>
<div class="main_wrp">
    <NavBottom />
    <SearchTop />
    <div class="main-body">
        <!-- <div class="nav_county">
            <span :class="gindx==index?'ac':''" v-for="(item,index) in series" :key="index" :data-indx="index" :data-area_id="item.Series_ID" @tap="showList(item.Series_ID,index)">{{item.Series_Name}}</span>
            <span class="ac">全部</span>
        </div> -->
        <!-- <div class="class-stu">
            <img class="stu-banner" :src="imgActiveAd" alt="banner">
        </div> -->
        <div class="nav-wrap">
            <div class="class-nav-container">
                <div class="class-nav-outer">
                    <div class="class-nav">
                        <a href="javascript:void(0);" @click="getBrandsLevel(12,'tab')">
                            <span class="specil-tag">新</span>
                            <div class="logo-wrap">
                                <img src="../assets/shop_navIcon01.png" alt="" />
                            </div>
                            <div class="class-navtext">亲子活动</div>
                        </a>
                        <a href="javascript:void(0);" @click="getBrandsLevel(13,'tab')">
                            <div class="logo-wrap">
                                <img src="../assets/shop_navIcon02.png" alt="" />
                            </div>
                            <div class="class-navtext">活动套装</div>
                        </a>
                        <a href="javascript:void(0);" @click="getBrandsLevel(14,'tab')">
                            <span class="specil-tag">必看</span>
                            <div class="logo-wrap">
                                <img src="../assets/shop_navIcon03.png" alt="" />
                            </div>
                            <div class="class-navtext">扶农助农</div>
                        </a>
                        <a href="javascript:void(0);" @click="getBrandsLevel(15,'tab')">
                            <span class="specil-tag">热</span>
                            <div class="logo-wrap">
                                <img src="../assets/shop_navIcon04.png" alt="" />
                            </div>
                            <div class="class-navtext">学而有用</div>
                        </a>

                    </div>
                </div>
            </div>
        </div>
        <div class="card_list">
            <template v-if="plist.length == 0">
                <div class="no-data">
                    抱歉，当前服务器未查询到相关数据！！！
                </div>
            </template>
            <template v-else>
                <div class="card_wrp_our">
                    <div class="card_wrp" v-for="(item,index) in plist" :key="index">
                        <!-- <span class="actives-statu" :class="{'on':computeStatu(item.Product_FlashStart,item.Product_FlashEnd)=='进行中'}">{{computeStatu(item.Product_FlashStart,item.Product_FlashEnd)}}</span> -->
                        <router-link class="card_main" :to="'/emporiumDetails/'+item.Product_ID">
                            <img :src="item.Product_ImageUrl?domin+item.Product_ImageUrl:standImg" />
                            <div class="card_text">
                                <div class="card_tit">
                                    {{item.Product_Name}}
                                </div>
                                <div class="card-last-wrp">
                                    <div>
                                        <div class="card_intro">
                                            <span v-if="item.Product_OriPrice">原价：{{item.Product_OriPrice}}元</span>
                                        </div>
                                        <div class="card_realInfo">
                                            <span>¥ {{item.Product_SellPrice}}元</span><text v-if="item.Product_integral"> + {{item.Product_integral}}积分</text>
                                        </div>
                                    </div>
                                    <div class="tb">查看</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <a href="javascript:void(0);" class="more-data" @click="loadmore">加载更多</a>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            standImg: require('../assets/standImg.jpg'),
            brandID: 0,
            Series_ID: 0,
            pageindex: 1,
            gindx: 0,
            series: [],
            plist: []
        };
    },
    mounted() {
        console.log(this.$route.params.id);
        this.getBrandsLevel(this.$route.params.id);
    },
    methods: {
        computeStatu(start, end) {
            let statuText = "";
            let starts = start.replace(/-/g, "/")
            let ends = end.replace(/-/g, "/")
            let stime = new Date(starts).getTime()
            let etime = new Date(ends).getTime()
            let now = Date.now()

            if (now < stime) {
                statuText = "未开始";
            } else if (now > stime && now < etime) {
                statuText = "进行中";

            } else if (now > etime) {
                statuText = "已结束";

            } else {
                statuText = "未知";
            }

            return statuText
        },
        // 获取左侧导航栏内的二级分类
        getBrandsLevel(id, type) {
            if (type == "tab") {
                this.brandID = id;
                this.pageindex = 1;
                this.plist = [];
                this.$router.push({
                    path: '/emporiumList/' + id
                });
            } else {
                this.brandID = this.$route.params.id;
            }
            this.wpost({
                url: "/Ajax/WordWelfare/getserieslist",
                data: {
                    brandid: id
                },
                succ: res => {
                    this.series = res.list;
                    //成功后, 拉取第一个系列的列表

                    this.showList(this.series.length > 0 ? this.series[0].Series_ID : 0, 0);
                }
            });
        },

        // 获取保对应商品列表
        showList(id, index) {
            this.Series_ID = id;
            this.wpost({
                url: "/Ajax/WordWelfare/getproductmateriallist",
                data: {
                    brandid: this.brandID,
                    // seriesid: id,
                    pageindex: this.pageindex
                },
                succ: data => {
                    this.plist = data.list;
                }
            });
            // 切换点中分类的显示效果
            this.gindx = index;
        },
        // 下拉获取更多
        loadmore() {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductmateriallist",
                data: {
                    brandid: this.brandID,
                    // seriesid: this.Series_ID,
                    pageindex: (this.pageindex += 1)
                },
                succ: data => {
                    if (data.list.length > 0) {
                        this.plist = this.plist.concat(data.list);
                    } else {
                        alert("暂无更多相关数据...");
                        this.pageindex = this.pageindex -= 1;
                    }
                }
            });
            console.log("pageindex:", this.pageindex);
        }
    }
};
</script>

<style lang="css" scoped>
.main_wrp {
    position: relative;
    background-image: linear-gradient(to bottom,
            #4B8FF4 0%,
            #4B8FF4 50px,
            #fff 51px,
            #fff);
    background-color: #f5f5f5;
}

.class-stu {
    margin: 10px 5px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 0;
}

.stu-banner {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.main-body {
    position: relative;
}

.nav-wrap {
    width: 100%;

}

.nav_county {
    width: 60px;
    height: calc(100vh - 120px);
    text-align: center;
    position: fixed;
    left: 0;
    top: 50px;
    z-index: 99;
}

.nav_county span {
    display: block;
    padding: 12px 0;
    font-size: 13px;
    background-color: #ececec;
    color: #888;
    border: 1px solid #dedede;
    border-top: none;
}

.nav_county span.ac {
    color: #f97a0a;
    background: #fff;
}

.card_list {
    box-sizing: border-box;
    width: 100%;
}

.card_wrp_our {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.card_wrp {
    width: calc(100% - 6px);
    margin: 6px 3px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 6px #ededed;
    background-color: #fff;
    position: relative;
}

.card_main {
    padding: 8px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.card_main img {
    width: 100px;
    height: 80px !important;
    padding: 3px;
    border-radius: 4px;
    margin-left: 3px;
}

.card_tit {
    font-weight: bold;
    color: #333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 4px;
}

.card_text {
    width: calc(100% - 110px);
    padding: 6px;
    box-sizing: border-box;
}

.card_intro {
    color: #a0a0a0;
    font-size: 12px;
    text-decoration: line-through;
}

.card_realInfo {
    color: #a0a0a0;
    font-size: 12px;
}

.card_realInfo span {
    color: #f97a0a;
    font-size: 12px;
}

.card-last-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tb {
    color: #fff;
    font-size: 13px;
    background-color: #f97a0a;
    border-radius: 20px;
    padding: 3px 5px;
    width: 47px;
    text-align: center;
    display: inline-block;
}

.card_btm {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.card_area {
    font-size: 13px;
    color: #f97a0a;
    border: 1px solid #f97a0a;
    padding: 1px 6px;
}

.card_price {
    font-size: 13px;
    color: #999;
}

.card_price text {
    font-size: 17px;
    margin-right: 4px;
    font-weight: bold;
    color: #f97a0a;
}

/* 大于五个导航样式 */
.class-nav-container {
    position: relative;
}

.class-nav-outer {
    text-align: center;
    width: calc(100% - 10px);
    overflow: hidden;
    overflow-x: auto;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px 0;
    padding-bottom: 22px;
    margin: 0 5px;
    /* scrollbar: none;
  scrollbar-color:transparent transparent; */
}

.class-nav-dot-wrap {
    bottom: 10px;
    left: 50%;
    width: 30px;
    transform: translateX(-50%);
    position: absolute;
    background-color: #ccc;
    height: 6px;
    border-radius: 3px;
}

.class-nav-dot {
    transition: transform 100ms ease;
    background-color: #4790f2;
    height: 6px;
    position: absolute;
    left: 0;
    transform: translateX(0);
    border-radius: 3px;
    width: 20px;
}

.class-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
}

.class-nav a {
    display: block;
    width: 20%;
    flex: none;
    position: relative;
    text-decoration: none;
}
.logo-wrap {
    height: 40px;
    width: 40px;
    margin: 3px auto;
    text-align: center;
    border-radius: 50%;
    background-image: linear-gradient(to right, #606cfc, #4692f1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-wrap img {
    height: 60%;
    vertical-align: middle;
}

.class-navtext {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

.specil-tag {
    right: 0;
    background-image: linear-gradient(to right, #2df0e4, #d34dfc);
}

.specil-tag::after {
    background-image: linear-gradient(50deg, #2df0e4, #4cd2e8);
}
</style>
